@media (width >= 992px) {
  .section {
    padding: 5rem;
  }

  .map-info-container {
    z-index: 10;
    float: left;
    width: 350px;
    padding: 40px 40px 15px;
    position: absolute;
    top: 10vh;
    right: 5vw;
  }
}

@media (width >= 1270px) {
  .container {
    max-width: 1240px;
  }
}

/*# sourceMappingURL=index.d9f1ac1e.css.map */
