/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .section {
        padding: 5rem;
    }

    .map-info-container {
        position: absolute;
        z-index: 10;
        float: left;
        width: 350px;
        padding: 40px;
        right: 5vw;
        top: 10vh;
        padding-bottom: 15px;
    }
}
/* Extra large devices (large desktops only, 1200px) */
@media (min-width: 1270px) {
    .container {
        max-width: 1240px;
    }
}
